import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import { Link } from 'react-router-dom';
import { FaucetIcon } from '../../Assets/Icons/Faucet';
import retroIcon from '../../Assets/Icons/retroIcon.svg';
import { ROUTES } from '../../constants/routes.constants';
import MobileMenu from '../MobileMenu';
import './styles.css';

export function Header() {
  return (
    <header className="header">
      <Link to={ROUTES.SEND_PAGE}>
        <img
          src={retroIcon}
          alt="logo"
          style={{ width: '170px', height: '40px' }}
        />
      </Link>

      <nav className="header-nav">
        <Link
          to={ROUTES.FAUCET_PAGE}
          className="header-nav-item cursor-default"
        >
          <FaucetIcon />
          Faucet
        </Link>
        <Link to={ROUTES.QUESTS_PAGE} className="header-nav-item">
          <RetroBridgerPointsIcon width={18} height={13} />
          Retro Points
        </Link>
        <Link
          to={ROUTES.SEND_PAGE}
          className="headerBtn header-nav-item fw-bold"
        >
          Launch App
        </Link>
      </nav>
      <div className="d-flex align-items-center ms-auto d-lg-none">
        <Link
          to={ROUTES.SEND_PAGE}
          className="btnGradient header-nav-item--btn"
        >
          App
        </Link>
        <MobileMenu />
      </div>
    </header>
  );
}
