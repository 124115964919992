import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface OtherItemProps {
  backgroundSrc?: string;
  href: string;
  children?: ReactNode;
  target?: string;
}

export function OtherItem({
  backgroundSrc,
  href,
  target,
  children,
}: OtherItemProps) {
  return (
    <Link
      to={href}
      target={target}
      className="notifications-menu--other-item"
      style={{
        backgroundImage: `url(${backgroundSrc})`,
      }}
    >
      {children}
    </Link>
  );
}
